import { Component, Inject, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CaseTaskListingFilters, CaseTasksService } from "@api/case-tasks";
import { sortTasksWithChildren } from "@api/case-tasks/operators";
import { CaseData, EligibleUserData } from "@api/cases";
import { ItemsListComponent } from "@modules/shared/_components/items-list/items-list.component";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { Observable, Subject, combineLatest, finalize, takeUntil } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from "rxjs/operators";

@Component({
  templateUrl: "./task-create.component.html",
  styleUrls: ["./task-create.component.scss"],
})
export class TaskCreateComponent extends ItemsListComponent implements OnInit {
  public taskForm = new FormGroup({
    name: new FormControl("", Validators.required),
    duration: new FormControl("", [Validators.required, Validators.min(1)]),
    dependent_task_id: new FormControl(null, Validators.required),
    assignee_id: new FormControl(
      {
        value: null,
        disabled: true,
      },
      Validators.required
    ),
  });

  users$: Observable<EligibleUserData[]>;
  filters: Partial<CaseTaskListingFilters> = {};
  startAfterOptions$: Observable<any>;
  private unsubscribe$: Subject<void> = new Subject<void>();
  assigneeSearchControl = new UntypedFormControl();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      action: {
        text: string;
      };
      case: CaseData & { shouldShowPreview: boolean };
      parentId?: number;
      case_service_id: number;
    },
    private dialogRef: MatDialogRef<TaskCreateComponent>,
    private caseTasksService: CaseTasksService,
    private caseTaskService: CaseTasksService
  ) {
    super();
  }

  ngOnInit() {
    this.getTasksList();

    this.users$ = combineLatest([
      this.startAfterControl.valueChanges,
      this.assigneeSearchControl.valueChanges.pipe(
        startWith(""),
        debounceTime(300),
        distinctUntilChanged()
      ),
    ]).pipe(
      tap(() => {
        this.assigneeControl.reset({
          value: undefined,
          disabled: true,
        });
      }),
      takeUntil(this.unsubscribe$),
      switchMap(([startAfterValue, search_text]) => {
        return this.caseTaskService.getEligibleUsers(
          this.data.case.id,
          startAfterValue,
          {
            search_text,
          }
        );
      }),
      map((users) => [...users]),
      tap(() => {
        this.assigneeControl.enable();
      })
    );
  }

  get startAfterControl() {
    return this.taskForm.controls.dependent_task_id;
  }
  get assigneeControl() {
    return this.taskForm.controls.assignee_id;
  }
  submit() {
    this.taskForm.markAllAsTouched();

    if (!this.taskForm.valid) {
      return;
    }
    const formValue = this.taskForm.getRawValue();
    const data: any = {
      ...formValue,
      expat_case_id: this.data.case.id,
      parent_id: this.data.parentId,
      case_service_id: this.data.case_service_id,
    };

    this.isLoading = true;

    this.caseTasksService.create(data).subscribe((data) => {
      this.dialogRef.close({
        type: "saved",
        task: data.task,
      });
    });
  }

  getTasksList() {
    this.filters.case_service_id = this.data.case_service_id.toString();
    this.filters.expat_case_id = this.data.case.id.toString();
    this.filters.per_page = "100";
    this.filters.include = "";

    this.startAfterOptions$ = this.caseTasksService
      .fetchTasks(this.filters)
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => (this.isLoading = false)),
        map((res) => res.items),
        sortTasksWithChildren(),
        map((tasks) =>
          tasks.map((item) => ({
            id: item.id,
            name: item.name,
            subtask: !!item.parent_id,
            disable:
              item.is_parent ||
              (this.data.parentId < item.id &&
                item.parent_id !== this.data.parentId),
          }))
        ),
        shareReplay(1)
      );
  }

  public close(): void {
    this.dialogRef.close({ action: "cancel" });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
