import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environment/environment";
import { SocialAccount } from "@models/social-account";
import { map, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SocialAccountService {
  constructor(private http: HttpClient) {}

  getAccount(): Observable<SocialAccount> {
    return this.http
      .get(`${environment.gateway_endpoint}socialite/account`)
      .pipe(map((response: any) => response.result));
  }

  linkAccount(driver: string, redirectUrl: string): Observable<any> {
    return this.http
      .get(`${environment.gateway_endpoint}auth/socialite/${driver}`, {
        params: {
          redirect_url: redirectUrl,
        },
      })
      .pipe(map((response: any) => response.result));
  }

  callback(driver: string, code: string, redirectUrl: string): Observable<any> {
    return this.http.post(
      `${environment.gateway_endpoint}auth/socialite/callback/${driver}`,
      {
        code,
        redirect_url: redirectUrl,
      }
    );
  }

  unlinkAccount(): Observable<any> {
    return this.http
      .delete(`${environment.gateway_endpoint}socialite/account`, {})
      .pipe(map((response: any) => response.result));
  }
}
