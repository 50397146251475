<div class="loading-wrapper" *ngIf="isLoading">
  <app-loader-spinner-general
    class="w-profile"
    [loadingType]="LoadingType.PROFILE"
  ></app-loader-spinner-general>
  <app-loader-spinner-general
    class="w-info"
    [loadingType]="LoadingType.BIG_INFO"
  ></app-loader-spinner-general>
</div>

<div class="details-body-wrapper" *ngIf="!isLoading">
  <div class="details-2c">
    <div *ngIf="userInfo">
      <!-- left -->
      <div class="details-2c-left">
        <div class="small-box">
          <div class="small-box-header-img" (click)="openDocModal()">
            <img
              [src]="
                userInfo.primary_image_path
                  ? userInfo.primary_image_path
                  : '../assets/images/profile-pics-placeholder.jpg'
              "
              alt="img"
              class="profile-pic"
            />
            <div class="edit-trigger">
              {{ "GENERALS.CHANGE-PIC" | translate }}
            </div>
          </div>
          <div class="small-box-details">
            <div class="medium-title">{{ userInfo.name }}</div>

            <div class="small-box-status">
              <div
                class="small-box-status entity_status-label-{{
                  userInfo.status_id
                }}"
              >
                {{ userInfo.status_name }}
              </div>
            </div>

            <div class="fw-500">
              <span class="gray block-span"
                >{{ "GENERALS.REGISTRED-ON" | translate }}:</span
              >
              {{ userInfo.created_at | date : "dd/MM/yyyy" }}
            </div>
          </div>
          <div class="small-box-list">
            <ul>
              <li class="action-link-gray" (click)="openResetPasswordModal()">
                <i class="icon-right-dir"></i>
                {{ "AUTH.CHANGE-PASSWORD" | translate }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- end left -->

      <!-- right -->
      <div class="details-2c-right">
        <div class="white-box-wrapper">
          <div class="white-box-row">
            <div class="white-box-header">
              <div class="medium-title fl-l">
                {{ "GENERALS.ACCOUNT-INFO" | translate }}
              </div>
              <div
                class="white-box-header-action fl-r"
                (click)="openEditModal()"
              >
                {{ "GENERALS.EDIT" | translate }}
                <i class="icon-cog-outline"></i>
              </div>
            </div>
            <div class="clear"></div>
            <div class="white-box-body">
              <p>{{ userInfo.name }}</p>
              <p>
                {{ userInfo.company_position }}
                <!-- {{ userInfo.department_name }} -->
              </p>
              <p>
                {{ userInfo.role_name }}
              </p>
              <p>
                <a href="mailto:{{ userInfo.email }}">
                  <i class="icon-mail"></i>
                  {{ userInfo.email }}
                </a>
              </p>
              <p>
                <i class="icon-phone"></i>
                {{ dictionaryService.getPhonePrefix(userInfo.phone_prefix) }}
                {{ userInfo.phone }}
              </p>
              <mat-divider class="my-4"></mat-divider>
              <app-link-social-account
                [redirectUrl]="oAuthSocialiteRedirectUrl"
              ></app-link-social-account>
            </div>
          </div>
        </div>
        <!-- end right -->
      </div>
      <!-- end 2 col details -->
    </div>
  </div>
</div>
