<div class="link-social-account">
  <h2 class="medium-title mb-2">{{ "EMAIL_INBOX.LINK_INBOX" | translate }}</h2>
  <p>{{ "EMAIL_INBOX.CONNECT_EMAIL_DESCRIPTION" | translate }}</p>

  <div
    class="w-1/2"
    *ngIf="socialAccount$ | async as socialAccount; else showOptions"
  >
    <div
      (click)="confirmUnlink()"
      class="link-item p-2 inline-flex items-center cursor-pointer contected"
    >
      <div class="flex justify-between w-full">
        <div class="flex items-center">
          <mat-icon
            [svgIcon]="socialAccount.driver_type | socialDriverIcon"
            class="mx-3"
          ></mat-icon>
          {{ socialAccount.email }}
        </div>
        <span class="disconnect ml-auto self-center mx-3 font-semibold">{{
          "EMAIL_INBOX.DISCONNECT" | translate
        }}</span>
      </div>
    </div>
  </div>

  <ng-template #showOptions>
    <div class="flex space-x-4" *ngIf="!isLoading">
      <div
        (click)="linkAccount(SocialDriverTypeEnum.GOOGLE)"
        class="link-item p-2 inline-flex items-center font-semibold cursor-pointer"
      >
        <mat-icon svgIcon="gmail" class="mx-3"></mat-icon>
        {{ "EMAIL_INBOX.GMAIL" | translate }}
      </div>
      <div
        (click)="linkAccount(SocialDriverTypeEnum.MICROSOFT)"
        class="link-item p-2 inline-flex items-center font-semibold cursor-pointer"
      >
        <mat-icon svgIcon="outlook" class="mx-3"></mat-icon>
        {{ "EMAIL_INBOX.OUTLOOK" | translate }}
      </div>
    </div>
  </ng-template>

  <div class="flex" *ngIf="isLoading">
    <app-loader-spinner-general
      *ngFor="let _ of [1, 2]"
      [loadingType]="LoadingTypeEnum.BUTTONS"
    ></app-loader-spinner-general>
  </div>

  <ul class="ml-4 mt-3">
    <li>
      {{ "EMAIL_INBOX.CENTRALIZES_COMMUNICATION" | translate }}
    </li>
    <li>
      {{ "EMAIL_INBOX.STREAMLINES_WORKFLOWS" | translate }}
    </li>
    <li>
      {{ "EMAIL_INBOX.BOOSTS_COLLABORATION" | translate }}
    </li>
  </ul>
</div>
