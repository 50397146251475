import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "socialDriverIcon",
})
export class SocialDriverIconPipe implements PipeTransform {
  private driverIconMap: { [key: string]: string } = {
    google: "gmail",
    microsoft: "outlook",
  };

  transform(driverType: string): string {
    return this.driverIconMap[driverType] || "";
  }
}
